import styled from 'styled-components'

import { Link } from 'gatsby'
import { media } from '~styles/mediaQuery'

const ServiceImage = styled(Link)`
  ${media.mq234`
    grid-row: 1 / 4;
    grid-column: ${({ position }) => (position === 'left' ? '1 / 3' : '2 / 4')};
  `};
`

export default ServiceImage
