import styled from 'styled-components'

import { media } from '~styles/mediaQuery'

const Content = styled.div`
  display: grid;
  ${media.mq1`
    grid-row-gap: ${(props) => props.theme.spacing[32]};
    
  `};
  ${media.mq234`
    grid-row-gap: ${(props) => props.theme.spacing[64]};
  `};
`

export default Content
