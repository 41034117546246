const messages = {
  title1: 'Personal Training / Online Coaching',
  description1:
    'Personal Training ist der optimale Ausgleich zu deinem Alltag und die ideale Lösung um schnell und effektiv dein Ziel zu erreichen.\n\n#Ausdauer #Muskelaufbau #Prävention #Individuell',
  imageAlt1: 'Tania trainiert mit einer Kettlebell',
  button1: 'starte heute',
  title2: 'Ernährung',
  description2:
    'Wir stellen deine Ernähung systematisch und schrittweise, ganz ohne hungern und Verbote, auf eine dauerhaft gesündere Ernährungsweise um.\n\n#Gewichtskontrolle #Abnehmen #Zunehmen #Balance',
  imageAlt2: 'Bananen und Haferkekse',
  button2: 'starte heute',
  title3: 'Gruppen Fitness',
  description3:
    'Bringe deine Freunde zusammen und lass uns zusammen trainieren! Denn in einer Guppe trainieren sorgt für zusätzliche Motivation und macht Spass.\n\n#Motivation #Team #Stressreduktion',
  imageAlt3:
    'Eine Gruppe von Menschen, die sich nach einem Training die Hände geben',
  button3: 'starte heute',
}

export default messages
