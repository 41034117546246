import React from 'react'
import PropTypes from 'prop-types'

import MaxWidthWrapper from '~components/MaxWidthWrapper'
import Layout from '~components/Layout'
import Typography from '~components/Typography'
import QuoteAuthor from '~components/QuoteAuthor'
import Button from '~components/Button'
import { NAVIGATION } from '~utils/constants'

import Content from './Content'
import Services from './Services'
import Header from './Header'
import messages from './messages'
import IndividualServiceImg from './IndividualServiceImg'
import IndividualService from './IndividualService'
import IndividualServiceText from './IndividualServiceText'

const metaTags = {
  title: 'Angebot Personal Training in Zürich',
  description:
    'Ein ganzheitliches Fitnesskonzept mit Training und Ernährung. Mit Qualität zu mehr Wohlbefinden. Buche jetzt dein Probetraining.',
  slug: NAVIGATION.SERVICE.SERVICE,
  image: {
    url: 'PageServices/HeroServices_01--large',
    alt: 'Tania hilf einer Kundin beim machen von Klimmzügen im Wald',
  },
}

const hero = {
  pictureData: {
    path: 'PageServices',
    alt: 'Tania hilf einer Kundin beim machen von Klimmzügen im Wald',
    fallback: {
      name: 'HeroServices_01--large',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MDE2IDMzODQiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1MzY4NDciIGQ9Ik0wIDBoNjAxNnYzMzg0SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09Im1hdHJpeCgyMy41IDAgMCAyMy41IDExLjggMTEuOCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNmZmY1ZmQiIGN4PSIyMTAiIGN5PSIxMjQiIHJ4PSIxOSIgcnk9IjMwIi8+PGVsbGlwc2UgZmlsbD0iI2MzYzNmYyIgY3g9IjE1MCIgY3k9IjEwNSIgcng9IjIyIiByeT0iMzQiLz48ZWxsaXBzZSBmaWxsPSIjYWZlMjkyIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC01NC42IDQ3LjYgMjQuNikgc2NhbGUoMTMzLjIxMjQ4IDM0LjI0NDg1KSIvPjxlbGxpcHNlIGZpbGw9IiMwMTE1MDAiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoNS42NTIzNSA4Ny40MDY4NSAtMzIuNjgwMjcgMi4xMTMzNCA5NSAxMjcuMikiLz48ZWxsaXBzZSBmaWxsPSIjMDAxYzAwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0zLjUxNjk2IDQzLjAxNzk4IC0yMi41MDM1NCAtMS44Mzk3OSAxODQuMiA1Mi41KSIvPjxwYXRoIGZpbGw9IiNhOWQ3OTYiIGQ9Ik01Ny4xIDExOS40bC0xNy42IDE0LjhMMyA5MC42bDE3LjYtMTQuOHoiLz48cGF0aCBmaWxsPSIjZDk5ODc4IiBkPSJNMTE1IDQzaDQ3djMwaC00N3oiLz48ZWxsaXBzZSBmaWxsPSIjMWQyZjE5IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDM3LjAzNzUgNzguODAxNzggLTE3LjU1Mjc5IDguMjQ5OTYgMjM2LjUgODIuMykiLz48L2c+PC9zdmc+',
    },
    breakpoints: {
      breakpointSmall: {
        name: 'HeroServices_01--small',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNjc3IDQwMTUiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1OTY3NGQiIGQ9Ik0wIDBoMjY4MXY0MDE1SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjggNy44KSBzY2FsZSgxNS42ODM2KSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iI2NjZDZmZiIgY3g9IjYzIiBjeT0iMjA2IiByeD0iMzMiIHJ5PSIzOSIvPjxlbGxpcHNlIGZpbGw9IiNmZmU3ZDQiIGN4PSIxNTEiIGN5PSIyMzQiIHJ4PSIyMCIgcnk9Ijg2Ii8+PHBhdGggZmlsbD0iIzAwMGMwMCIgZD0iTTExMC41IDE2OC4zbC00Ni04Ni41IDUzLTI4LjEgNDYgODYuNXoiLz48ZWxsaXBzZSByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0yMC45OTY3OSA0Ljg0NzUgLTM4LjEwNjkyIC0xNjUuMDU5MiA4IDIwNy43KSIvPjxlbGxpcHNlIGZpbGw9IiNjNWZmYmMiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoNy40ODYwMiAxMy41MDUxNCAtMjYuMjcyODMgMTQuNTYzMjcgMTE2LjYgMTYuNykiLz48cGF0aCBmaWxsPSIjZTNjNWNkIiBkPSJNODEuNyAxMDEuN2wxMS40IDEwOC40LTIwLjggMi4yLTExLjQtMTA4LjR6Ii8+PHBhdGggZD0iTTAgMjQzaDEzMXYxM0gweiIvPjxwYXRoIGZpbGw9IiNmZmZlZjgiIGQ9Ik0xNDYgMjIxbC00Mi43LTIzLjggODIuNy00LjZ2MjIuOXoiLz48L2c+PC9zdmc+',
      },
      breakpointMedium: {
        name: 'HeroServices_01--medium',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzOTczIDM5NzMiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1MTYyNDYiIGQ9Ik0wIDBoMzk3M3YzOTczSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjggNy44KSBzY2FsZSgxNS41MTk1MykiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNmZmZhZmYiIGN4PSIyMDkiIGN5PSIyMjEiIHJ4PSIyOSIgcnk9IjQzIi8+PHBhdGggZmlsbD0iI2Q2ZGNmZiIgZD0iTTgzIDE2NGg2MXY3NEg4M3oiLz48ZWxsaXBzZSBmaWxsPSIjMDAxMjAwIiBjeD0iMTciIGN5PSIyMTkiIHJ4PSI2MyIgcnk9IjEwNCIvPjxlbGxpcHNlIGZpbGw9IiMwMDFiMDAiIGN4PSIxNjgiIGN5PSIxMTEiIHJ4PSIzNSIgcnk9IjU5Ii8+PGVsbGlwc2UgZmlsbD0iI2M1ZmZiYiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCg4LjYwMTY3IDE2LjA4MzY5IC0yOC41MzU5NSAxNS4yNjEyMiAxNzAgMTMuNSkiLz48cGF0aCBmaWxsPSIjZmZlMWU3IiBkPSJNMTE4IDEwNWgxN3Y1OWgtMTd6Ii8+PHBhdGggZmlsbD0iI2ZmZGVhOCIgZD0iTTE4OCAxNjNsMTUtMjggMjcgOTd6Ii8+PGVsbGlwc2UgY3g9IjExNCIgY3k9IjI1MiIgcng9IjY2IiByeT0iOSIvPjwvZz48L3N2Zz4=',
      },
      breakpointLarge: {
        name: 'HeroServices_01--large',
        placeholder:
          'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2MDE2IDMzODQiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1MzY4NDciIGQ9Ik0wIDBoNjAxNnYzMzg0SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09Im1hdHJpeCgyMy41IDAgMCAyMy41IDExLjggMTEuOCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNmZmY1ZmQiIGN4PSIyMTAiIGN5PSIxMjQiIHJ4PSIxOSIgcnk9IjMwIi8+PGVsbGlwc2UgZmlsbD0iI2MzYzNmYyIgY3g9IjE1MCIgY3k9IjEwNSIgcng9IjIyIiByeT0iMzQiLz48ZWxsaXBzZSBmaWxsPSIjYWZlMjkyIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC01NC42IDQ3LjYgMjQuNikgc2NhbGUoMTMzLjIxMjQ4IDM0LjI0NDg1KSIvPjxlbGxpcHNlIGZpbGw9IiMwMTE1MDAiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoNS42NTIzNSA4Ny40MDY4NSAtMzIuNjgwMjcgMi4xMTMzNCA5NSAxMjcuMikiLz48ZWxsaXBzZSBmaWxsPSIjMDAxYzAwIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KC0zLjUxNjk2IDQzLjAxNzk4IC0yMi41MDM1NCAtMS44Mzk3OSAxODQuMiA1Mi41KSIvPjxwYXRoIGZpbGw9IiNhOWQ3OTYiIGQ9Ik01Ny4xIDExOS40bC0xNy42IDE0LjhMMyA5MC42bDE3LjYtMTQuOHoiLz48cGF0aCBmaWxsPSIjZDk5ODc4IiBkPSJNMTE1IDQzaDQ3djMwaC00N3oiLz48ZWxsaXBzZSBmaWxsPSIjMWQyZjE5IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDM3LjAzNzUgNzguODAxNzggLTE3LjU1Mjc5IDguMjQ5OTYgMjM2LjUgODIuMykiLz48L2c+PC9zdmc+',
      },
    },
  },
  textData: {
    header: messages.header,
  },
}

const individualService = {
  alt: messages.individualServiceImageAlt,
  name: 'Pushup_Workout_01',
  path: 'PageServices',
  placeholder:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1ODQ2IDI5MjMiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM1ZDVmNWEiIGQ9Ik0wIDBoNTg0NnYyOTIzSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMS40IDExLjQpIHNjYWxlKDIyLjgzNTk0KSIgZmlsbC1vcGFjaXR5PSIuNSI+PGVsbGlwc2UgZmlsbD0iIzAyMGYwNiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCguNTAxOTQgMjQuMzI0NjggLTEzMS42MjM0OCAyLjcxNjA1IDEwNS40IDExNS4yKSIvPjxlbGxpcHNlIGZpbGw9IiM5YzljOWQiIGN4PSIyMzQiIGN5PSI1OSIgcng9IjQzIiByeT0iNDMiLz48ZWxsaXBzZSBmaWxsPSIjOTA5MDhmIiBjeD0iMjgiIGN5PSIxOSIgcng9IjY0IiByeT0iNjkiLz48cGF0aCBkPSJNMjM3IDE2TDEzOS0ybDExMC0xNHoiLz48cGF0aCBmaWxsPSIjZmZjNmE3IiBkPSJNMTEwLjYgMTA0LjlsMTUuOS0xMi43LTE0LjQtOS4zLTI3LjMgMTMuOHoiLz48ZWxsaXBzZSBmaWxsPSIjN2U4Mjg1IiBjeD0iMTUzIiBjeT0iMzYiIHJ4PSI2OCIgcnk9IjMyIi8+PGVsbGlwc2UgZmlsbD0iIzAwMTMyOCIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgzMC42NDMyNyAzMi4yNDMwOCAtMTEuMTUzOTMgMTAuNjAwNSAyMDAuNiAxMDcuMikiLz48cGF0aCBmaWxsPSIjMzQxYTBkIiBkPSJNOTUgNTBsNzIgNDlMNTUgNjZ6Ii8+PC9nPjwvc3ZnPg==',
}

const PageServices = ({ location }) => (
  <Layout
    hero={hero}
    pictureData={hero.pictureData}
    metaTags={metaTags}
    pathname={location.pathname}
  >
    <Content>
      <Header as="h2" type="header2" limit align="center" uppercase>
        {messages.title}
      </Header>
      <Typography limit>{messages.paragraph1}</Typography>
      <Services />
      <QuoteAuthor>
        <QuoteAuthor.Quote align="center">{messages.quote}</QuoteAuthor.Quote>
        <QuoteAuthor.Author align="center">
          {messages.author}
        </QuoteAuthor.Author>
      </QuoteAuthor>
      <Typography limit>{messages.paragraph2}</Typography>
      <IndividualService>
        <IndividualServiceImg imageData={individualService} fit="cover" />
        <MaxWidthWrapper>
          <IndividualServiceText align="center" preWrap as="h3" type="header4">
            {messages.callToActionText}
          </IndividualServiceText>
          <Button align="center" to={NAVIGATION.CONTACT.CONTACT}>
            {messages.callToActionButton}
          </Button>
        </MaxWidthWrapper>
      </IndividualService>
    </Content>
  </Layout>
)

PageServices.propTypes = {
  location: PropTypes.object.isRequired,
}

export default PageServices
