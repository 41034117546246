const messages = {
  header: 'Angebot',
  title: 'Zusammen an deinem Erfolg arbeiten',
  quote:
    'Ich sage Dir nicht, dass es leicht wird. Ich sage Dir, dass es sich lohnen wird.',
  author: 'Art Williams',
  paragraph1:
    'Ich gehe individuell auf deine persönlichen Ziele ein und erstellen dir ein massgeschneidertes Programm, mit dem du sie erreichen kannst. Egal wie deine Ausgangslage ist, egal wieviel Zeit du hast. Es ist DEIN Programm. Zusammen trainieren wir effizient und effektiv bei dir, draussen oder bei mir im Studio.',
  paragraph2:
    'Kreiere einen Lebensstil, der durch die Unterstützung von Bewegung und Ernährung lebenslang anhält. Be the best version of yourself!',
  callToActionText:
    'Möchtest du kombinieren und ein individuelles Paket haben?\nFrag einfach nach! Wir finden eine Lösung',
  callToActionButton: 'Schreibe mir',
  individualServiceImageAlt: 'Tania am Liegestützen machen',
}

export default messages
