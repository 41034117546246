import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MaxWidthWrapper from '~components/MaxWidthWrapper'
import Typography from '~components/Typography'

import Wrapper from './Wrapper'
import Author from './Author'

class QuoteAuthor extends Component {
  static Author = Author

  static Quote = Typography

  render() {
    return (
      <Wrapper>
        <MaxWidthWrapper>{this.props.children}</MaxWidthWrapper>
      </Wrapper>
    )
  }
}

QuoteAuthor.propTypes = {
  children: PropTypes.node.isRequired,
}

export default QuoteAuthor
