import styled from 'styled-components'

import { fluidSpacingFunc } from '~styles/helpers'
import { shadows } from '~styles/constants'

const Wrapper = styled.section`
  ${fluidSpacingFunc([{ property: 'padding', values: [2, 0] }])};
  background: ${(prop) => prop.theme.palette.secondary.main};
  box-shadow: ${shadows[1]};
`

export default Wrapper
