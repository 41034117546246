/**
 *
 * Services
 *
 */

import React from 'react'

import { NAVIGATION } from '~utils/constants'
import Img from '~components/Img'
import Button from '~components/Button'

import messages from './messages'
import Section from './Section'
import ServiceGrid from './ServiceGrid'
import ServiceImage from './ServiceImage'
import Content from './Content'
import Description from './Description'
import Title from './Title'
import StyledTrapezoid from './StyledTrapezoid'

const servicesData = [
  {
    button: messages.button1,
    description: messages.description1,
    imageData: {
      alt: messages.imageAlt1,
      path: 'PageServices',
      name: 'PersonalTraining_01',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MDExIDQwMTEiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM4Yzg2N2MiIGQ9Ik0wIDBoNDAxMXY0MDExSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg3LjggNy44KSBzY2FsZSgxNS42Njc5NykiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNmZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoLTE2My43IDc4LjYgNikgc2NhbGUoMjU0LjMzOTA0IDYxLjQ0ODM1KSIvPjxlbGxpcHNlIGZpbGw9IiMyNDEwMDMiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTQ3LjU2NjcyIDg3LjI0MzU0IC02Ny42NzcxNyAtMzYuODk4NzkgMTAxLjggMTQ4KSIvPjxlbGxpcHNlIGZpbGw9IiNmZmYiIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTUxLjE1OTY4IDE4Ljc0MDYgLTEyLjU2OSAtMzQuMzExOTUgMjIwIDkzLjkpIi8+PGVsbGlwc2UgZmlsbD0iIzAwMDAwMyIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCgtMjAuNTQ2ODEgNi45OTQ3IC0xOS43NDU2IC01OC4wMDIzNSAxNTkuMiAxNjYuOCkiLz48cGF0aCBmaWxsPSIjZjVmNGYxIiBkPSJNNDggMGg0N3Y5N0g0OHoiLz48ZWxsaXBzZSBmaWxsPSIjMDAwMzAxIiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKDg2LjUgLTc2LjMgOTYuNSkgc2NhbGUoMTguOTc4NDMgODQuNTExODUpIi8+PGVsbGlwc2UgZmlsbD0iIzM0MDkwMCIgY3g9IjExOCIgY3k9IjcyIiByeD0iMjMiIHJ5PSIzMiIvPjxwYXRoIGZpbGw9IiNiODg1NjMiIGQ9Ik03My4zIDI1MmwzNC45LTk1LjggNTQuNSAxOS44LTM0LjkgOTUuOHoiLz48L2c+PC9zdmc+',
    },
    link: NAVIGATION.SERVICE.PERSONAL_TRAINING,
    title: messages.title1,
    position: 'left',
  },
  {
    button: messages.button2,
    description: messages.description2,
    imageData: {
      alt: messages.imageAlt2,
      name: 'Nutrition_01',
      path: 'PageServices',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzMzk5IDMzOTkiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiNiNDljODEiIGQ9Ik0wIDBoMzM5OXYzMzk5SDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2LjYgNi42KSBzY2FsZSgxMy4yNzczNCkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiMwMTAwMDAiIGN4PSIxMzciIGN5PSIyMCIgcng9IjM1IiByeT0iMzMiLz48ZWxsaXBzZSBmaWxsPSIjMDYwMDAwIiBjeD0iNzkiIGN5PSIxNDkiIHJ4PSIzMCIgcnk9IjMyIi8+PGVsbGlwc2Ugcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09Im1hdHJpeCguMzc5MjQgLTI1LjY1NDM3IDMyLjg4NjgzIC40ODYxNiAxODIuOCA5NykiLz48ZWxsaXBzZSByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0icm90YXRlKC00NS42IDMwMCAxNi41KSBzY2FsZSgyOC41Nzg2MiAxOC45NzkyKSIvPjxlbGxpcHNlIGZpbGw9IiNmMmU0ZTEiIGN4PSIyNTAiIGN5PSIxODkiIHJ4PSIzNSIgcnk9IjE5MCIvPjxlbGxpcHNlIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTExLjQ2NDEzIDIzLjMyMTMgLTEzLjE5OTYyIC02LjQ4ODU4IDE4MS4yIDE5OC41KSIvPjxlbGxpcHNlIGZpbGw9IiNlZWUwZDciIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJyb3RhdGUoLTMuMyAzMTczIC0yMzIxLjgpIHNjYWxlKDI0LjY0MjU0IDExMi4xMTM2MikiLz48ZWxsaXBzZSByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDMuNzIxNTIgLTQzLjQwOTYzIDE1Ljc5MDE0IDEuMzUzNyAuMSAxMjIuMSkiLz48L2c+PC9zdmc+',
    },
    link: NAVIGATION.SERVICE.NUTRITION,
    title: messages.title2,
    position: 'right',
  },
  {
    button: messages.button3,
    description: messages.description3,
    imageData: {
      alt: messages.imageAlt3,
      name: 'GroupFitness_01',
      path: 'PageServices',
      placeholder:
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNjcwIDI2NzAiPjxmaWx0ZXIgaWQ9ImIiPjxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEyIiAvPjwvZmlsdGVyPjxwYXRoIGZpbGw9IiM4NTZiNTQiIGQ9Ik0wIDBoMjY3MHYyNjcwSDB6Ii8+PGcgZmlsdGVyPSJ1cmwoI2IpIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1LjIgNS4yKSBzY2FsZSgxMC40Mjk2OSkiIGZpbGwtb3BhY2l0eT0iLjUiPjxlbGxpcHNlIGZpbGw9IiNmZmYiIGN5PSI3NSIgcng9IjEzOCIgcnk9IjQwIi8+PGVsbGlwc2UgY3k9IjIyNyIgcng9IjY2IiByeT0iNjYiLz48ZWxsaXBzZSByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDguNjYwOSAtNTcuOTUxNDEgMTguMzgzNTcgMi43NDc0NCAxNTYgODguNikiLz48ZWxsaXBzZSBmaWxsPSIjZmRkN2I5IiByeD0iMSIgcnk9IjEiIHRyYW5zZm9ybT0ibWF0cml4KDI5LjU3Nzg4IC0yLjI3MjUgNC43MDE2NCA2MS4xOTQzOCAyMTAuMSA5OS41KSIvPjxlbGxpcHNlIHJ4PSIxIiByeT0iMSIgdHJhbnNmb3JtPSJtYXRyaXgoLTEwLjU2NiAxNC44MjI0NSAtMjYuNjMxMjcgLTE4Ljk4Mzc4IDg2LjMgMTIzLjcpIi8+PGVsbGlwc2UgZmlsbD0iIzEyMDMwMCIgY3g9IjkxIiBjeT0iMTUiIHJ4PSI3MyIgcnk9IjI4Ii8+PGVsbGlwc2UgZmlsbD0iI2ZmZmZmNiIgcng9IjEiIHJ5PSIxIiB0cmFuc2Zvcm09InJvdGF0ZSg2MS4zIC00NS40IDUyKSBzY2FsZSgxMTMuNTMyODYgMTcuNzI4MjQpIi8+PHBhdGggZmlsbD0iIzA5MDAwMCIgZD0iTTE3Ny42IDI1Ni42bC0zNi45IDIuNi00LjMtNjEuOCAzNi45LTIuNnoiLz48L2c+PC9zdmc+',
    },
    link: NAVIGATION.SERVICE.GROUP_FITNESS,
    title: messages.title3,
    position: 'left',
  },
]

const Services = () => (
  <Section>
    {servicesData.map(
      ({ title, description, position, link, imageData, button }) => (
        <ServiceGrid key={link} position={position}>
          <ServiceImage to={link} position={position}>
            <Img imageData={imageData} />
          </ServiceImage>
          <StyledTrapezoid position={position}>
            <Content>
              <Title as="h3" type="header4" uppercase position={position}>
                {title}
              </Title>
              <Description preWrap position={position}>
                {description}
              </Description>
              <Button to={link} align="center">
                {button}
              </Button>
            </Content>
          </StyledTrapezoid>
        </ServiceGrid>
      ),
    )}
  </Section>
)

export default Services
