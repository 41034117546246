import styled from 'styled-components'

import { media } from '~styles/mediaQuery'
import { maxWidthStyle } from '~components/MaxWidthWrapper'

const Section = styled.section`
  display: grid;
  ${maxWidthStyle};
  ${media.mq1`
    max-width: 100%;
  `};
  ${media.mq2`
    grid-gap: ${({ theme }) => theme.spacing[32]};
  `};
  ${media.mq34`
    grid-gap: ${({ theme }) => theme.spacing[64]};
    grid-gap: ${({ theme }) => theme.spacing[64]};
  `};
`

export default Section
