import styled from 'styled-components'

import Typography from '~components/Typography'

const Title = styled(Typography)`
  color: ${({ theme, position }) =>
    position === 'right'
      ? theme.palette.text.primary.contrastText
      : theme.palette.text.primary.main};
  margin-bottom: ${({ theme }) => theme.spacing[16]};
`

export default Title
