import React, { useEffect, useRef, useReducer } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'

import { stringifySrcSet } from '~utils/helpers'
import { ROUNDING, MAX_PIXELDENSITY_ARRAY } from '~utils/constants'
import { maxWidthStyle } from '~components/MaxWidthWrapper'

import { imageDataPropTypes } from './propTypes'

const images = (
  { settings = 'f_auto,q_80', path, name },
  height,
  width,
  fit = '',
) => {
  // round aspect ratio to at most 2 decimal places
  // the plus sign drops any "extra" zeroes at the end.
  const aspectRatio = +(width / height).toFixed(2)
  const fitParams = `,c_fill,ar_${aspectRatio},g_auto`
  const optionalParams = `${settings}${fit && fitParams}`
  return MAX_PIXELDENSITY_ARRAY.map(
    (pixelDensity) =>
      // +1 since array starts from 0, pixel density from 1
      `https://res.cloudinary.com/taniaferreira/image/upload/c_scale,dpr_${
        pixelDensity + 1
      }.0,${optionalParams},h_${height}/${path}/${name}.jpg`,
  )
}

const initialState = {
  placeholderHeight: 0,
  placeholderWidth: 0,
  isLoaded: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'setDimensions':
      return {
        ...state,
        placeholderHeight:
          Math.ceil(action.ref.current.scrollHeight / ROUNDING) * ROUNDING,
        placeholderWidth:
          Math.ceil(action.ref.current.scrollWidth / ROUNDING) * ROUNDING,
      }
    case 'setIsLoaded':
      return { ...state, isLoaded: true }
    default:
      throw new Error()
  }
}

const Img = ({ className, imageData, limit, fit }) => {
  const [ref, inView] = useInView({ triggerOnce: true })
  const refPlaceholder = useRef(null)
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    dispatch({ type: 'setDimensions', ref: refPlaceholder })
  }, [refPlaceholder])

  return (
    <Wrapper limit={limit} className={className} fit={fit} ref={ref}>
      <Placeholder
        fit={fit}
        src={imageData.placeholder}
        alt={imageData.alt}
        isLoaded={state.isLoaded}
        // needed for page navigation (since on componentDidMount placeholderDimensions === 0)
        onLoad={() => dispatch({ type: 'setDimensions', ref: refPlaceholder })}
        ref={refPlaceholder}
      />

      {inView && (
        <Image
          fit={fit}
          srcSet={stringifySrcSet(
            images(
              imageData,
              state.placeholderHeight,
              state.placeholderWidth,
              fit,
            ),
          )}
          src={
            images(
              imageData,
              state.placeholderHeight,
              state.placeholderWidth,
              fit,
            )[0]
          }
          alt={imageData.alt}
          onLoad={() => dispatch({ type: 'setIsLoaded' })}
          isLoaded={state.isLoaded}
          crossOrigin="anonymous"
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  ${(props) => props.limit && maxWidthStyle};
  ${(props) => props.fit && `height: 100%`};
`

export const Placeholder = styled.img`
  display: block;
  filter: ${(props) => (props.isLoaded ? 'blur(0px)' : 'blur(12px)')};
  height: 100%;
  width: 100%;
  object-fit: ${(props) => props.fit};
`

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: ${(props) => props.fit};
  opacity: ${(props) => (props.isLoaded ? 1 : 0)};
`

Img.propTypes = {
  imageData: imageDataPropTypes.isRequired,
  className: PropTypes.string,
  limit: PropTypes.bool,
  fit: PropTypes.string,
}

export default Img
