import styled from 'styled-components'
import { rgba } from 'polished'

import Typography from '~components/Typography'
import { fluidSpacingFunc } from '~styles/helpers'

const IndividualServiceText = styled(Typography)`
  color: ${({ theme }) => theme.palette.common.white};
  border-radius: ${({ theme }) => theme.spacing[2]};
  background: ${({ theme }) => rgba(theme.palette.grey[900], 0.5)};
  ${fluidSpacingFunc([
    { property: 'padding', values: [3, 2] },
    { property: 'margin-bottom', values: [4] },
  ])};
`

export default IndividualServiceText
