import styled from 'styled-components'
import PropTypes from 'prop-types'

import Typography from '~components/Typography'

const Author = styled(Typography)`
  color: ${({ theme }) => theme.palette.primary.main};
  position: relative;

  &::before {
    content: '— ';
  }
`

Author.defaultProps = {
  type: 'header5',
}

Author.propTypes = {
  type: PropTypes.string,
}

export default Author
