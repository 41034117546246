import styled from 'styled-components'

import { fluidSpacingFunc } from '~styles/helpers'

const IndividualService = styled.section`
  ${fluidSpacingFunc([{ property: 'padding', values: [7, 0] }])};
  position: relative;
  overflow: hidden;
`

export default IndividualService
