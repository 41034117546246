import styled from 'styled-components'

import NormalImg from '~components/Img'

const Img = styled(NormalImg)`
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
`

export default Img
